
const getters = {
  menuData: state => state.settings.menuData,
  sponsorInfo: () => {
    let sponsorInfo;
    if (process.env.NODE_ENV == "development") {
      sponsorInfo = {
        t_sponsor_admin_id: 1,
        t_sponsor_id: 1,
        txt_sponsor_admin_name: "cui",
        password: "cxc123",
        txt_token: "581c9606-6ec4-4505-b5e1-ecb926cbe368",
        is_first_login: 1, //null 首次登录 需要提示修改密码(强制修改) 1不需要修改
        is_change: null //用于接近90天修改密码的状态 null 不需要修改 1需要提示修改密码(强制修改)
      }
    } else {
      sponsorInfo = JSON.parse(sessionStorage.getItem("sponsorInfo"));
    }
    return sponsorInfo;
  },
  // 生成随机字符串
  UUID() { return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function () { return (Math.random() * 16 | 0).toString(16); }); },
};

export default getters;