import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入 element-ui
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';

import $ from "jquery";

// 样式引入
import "./assets/index.scss";
// 引入iconfont
import "./assets/iconfont/iconfont.css";

const app = createApp(App);

// 自定义指令，阻止按钮多次重复点击
app.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 1000)
      }
    })
  }
});


app.use(store).use(ElementPlus, { locale }).use(router).use($).mount('#app')
